import {actions} from '../API';

const GET_PRODUCTS_BY_KIEV = 'GET-PRODUCTS-BY-KIEV';
const GET_PRODUCTS_BY_KHARKIV = 'GET-PRODUCTS-BY-KHARKIV';

const initValues = {
    productsByKiev: [],
    productsByKharkiv: [],
};

const ProductsReducer = (state = initValues, action) => {
    switch(action.type){
        case GET_PRODUCTS_BY_KIEV:
            const productsByKiev = [];
            action.data.map(product => productsByKiev.push({ ...product, city: 'kyiv' }));
            return { ...state, productsByKiev }; 
        case GET_PRODUCTS_BY_KHARKIV:
            const productsByKharkiv = [];
            action.data.map(product => productsByKharkiv.push({ ...product, city: 'kharkiv' }));
            return { ...state, productsByKharkiv };      
        default:
            return state;
    }
}

export const updateProductsByKiev = (data) => ({ type: GET_PRODUCTS_BY_KIEV, data });
export const updateProductsByKharkiv = (data) => ({ type: GET_PRODUCTS_BY_KHARKIV, data });

export const getProducts = (menuCity) => {
    let brandCode = menuCity === 'kyiv' ? '100000000' : '100000005';

    return actions.getProducts(brandCode)
                
};

export default ProductsReducer;