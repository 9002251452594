import {combineReducers, createStore} from 'redux';
import { reducer as formReducer } from 'redux-form';

import medals from './medals';
import products from './products';

let reducers = combineReducers({
    form: formReducer,
    medals,
    products,
})

const store = createStore(reducers);

export default store;