import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { withFirebase } from '../firebase';
import { makeStyles } from '@material-ui/core/styles';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme=>({
  table: {
    minWidth: 650,
    [theme.breakpoints.down('md')]: {
        minWidth: 400,
    },
    [theme.breakpoints.down('sm')]: {
        minWidth: 290,
    },
  },
}));

const DenseTable = (props) => {
    const { data, name } = props;
    const classes = useStyles();
    const productsByKiev = useSelector(state => state.products.productsByKiev);
    const productsByKharkiv = useSelector(state => state.products.productsByKharkiv);
    const [rows, setRows] = useState([]);

    const getRows = (data) => {
        let result = [];
        let productsCode = [];
        const products = [];
        const productsByCity =  name === 'kyiv' ? productsByKiev : productsByKharkiv;

        for (let key in data) {
            for (let id in data[key]) {
                const count = data[key][id];
                const product = productsByCity.find(t => t.Code === String(id));

                if (product) {
                    result.push({
                        name: product.Name ? product.Name : '',
                        count,
                        Code: product.Code
                    });

                     if (!productsCode.includes(product.Code)) {
                        productsCode.push(product.Code);
                    }
                }
            }
        }

        productsCode.forEach(Code => {
            const items = result.filter(t => t.Code === Code);
            const count = items.reduce((acc, curr) => {
                // console.log('acc', acc);
                // console.log('curr', curr);
                return acc + curr.count;
            }, 0);

            products.push({
                name: items[0].name,
                count
            });
        });


        // console.log('products', products);
        // console.log('products', products);
        // console.log('data', data);
        return products;
    }

    useEffect(() => {
        const rowsData = getRows(data);
        setRows(rowsData);
    }, [name, data]);

    // console.log('rows', rows);


    return (
        <TableContainer component={Paper}>
            <Table className={classes.table} size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell>Название продукта</TableCell>
                    <TableCell align="right">Количество</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row) => (
                    <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                        {row.name}
                    </TableCell>
                    <TableCell align="right">{row.count}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default withFirebase(DenseTable);