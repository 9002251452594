import * as axios from "axios";

const instance = axios.create({
    // baseURL: 'http://demo.ithinkers.ru/mamamanana/',
    baseURL: 'http://api.e-admin.com.ua/mamamanana/',
    crossDomain: true
});

export const actions = {
    getProducts(brand){
        return instance.get(`getProducts.php/?brand=${brand}`)
        .then (response => {
            return response.data;
        })
    }
};