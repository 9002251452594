import { createMuiTheme } from '@material-ui/core/styles';
import {blue, grey} from '@material-ui/core/colors';

const theme = createMuiTheme({
    props: {
      MuiTypography: {
        variantMapping: {
          h1: 'h2',
          h2: 'h2',
          h3: 'h2',
          h4: 'h2',
          h5: 'h2',
          h6: 'h2',
          subtitle1: 'h2',
          subtitle2: 'h2',
          body1: 'span',
          body2: 'span',
        },
      },
    },
    palette: {
        primary: {
          main: blue[700],
        },
        secondary: {
          main: grey[300],
        },
      },
  });

export default theme;