const GET_RECEIVED_MEDALS = 'GET-RECEIVED-MEDALS';
const GET_SPENT_MEDALS = 'GET-SPENT-MEDALS';

const initValues = {
    receivedMedals: {},
    spentMedals: {},
    count: 0,
};

const MedalsReducer = (state = initValues, action) => {
    switch(action.type){
        case GET_RECEIVED_MEDALS:
            return {
                ...state,
                receivedMedals: action.data
            }
        case GET_SPENT_MEDALS:
            return {
                ...state,
                spentMedals: action.data
            }
        
        default:
            return state;
    }
}

export const getSpentMedals = (data) => ({ type: GET_SPENT_MEDALS, data });
export const getReceivedMedals = (data) => ({ type: GET_RECEIVED_MEDALS, data });

export default MedalsReducer;