import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';

import Typography from '@material-ui/core/Typography';
import Calendar from '../../components/CustomCalendar';
import Table from '../../components/Table';

const Form = () => {
    const classes = useStyles();
    const state = useSelector(state => state);
    const spentMedals = useSelector(state => state.medals.spentMedals);
    const receivedMedals = useSelector(state => state.medals.receivedMedals);
    const isSpentMedals = Object.keys(spentMedals).length > 0;
    const isReceivedMedals = Object.keys(receivedMedals).length > 0;

    let count = 0;

    for (let key in receivedMedals) {
        for (let j in receivedMedals[key]) {
            count += receivedMedals[key][j]
        }
    }

    return (
        <div className={classes.root}>
            <div>
                <Typography gutterBottom variant="subtitle1">Дата (период)</Typography>
                <div className={classes.calendars}>
                    <Calendar />
                </div>
            </div>
            {isReceivedMedals && (
                <div className={classes.medalsContainer}>
                    <Typography gutterBottom variant="h6">К-во начисленных медалек</Typography>
                    <div className={classes.calendars}>
                        {count}
                    </div>
                </div>
            )}
            {isSpentMedals && (
                <div className={classes.medalsContainer}>
                    <Typography gutterBottom variant="h6">К-во потраченных медалек (Киев)</Typography>
                    <div className={classes.calendars}>
                        <Table name="kyiv" data={spentMedals} />
                    </div>
                </div>
            )}
            {isSpentMedals && (
                <div className={classes.medalsContainer}>
                    <Typography gutterBottom variant="h6">К-во потраченных медалек (Харьков)</Typography>
                    <div className={classes.calendars}>
                        <Table name="kharkiv" data={spentMedals} />
                    </div>
                </div>
            )}
        </div>
    )
}

const useStyles = makeStyles({
    root: {
      marginTop: '1rem',
    },
    calendars: {
        // display: 'flex',
        // width: 325,
    },
    medalsContainer: {
        marginTop: 30,
    }
  });

  export default Form;