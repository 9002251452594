import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/storage';

const config = {
    apiKey: "AIzaSyBEmNXCZ27_nXfnR40bmKz_HaICJsAlqpY",
    authDomain: "mamamanana-c8090.firebaseapp.com",
    databaseURL: "https://mamamanana-c8090.firebaseio.com",
    projectId: "mamamanana-c8090",
    storageBucket: "mamamanana-c8090.appspot.com",
    messagingSenderId: "1090119366298",
    appId: "1:1090119366298:web:2cc8571e7b410106"
};

class Firebase {
    constructor() {
      app.initializeApp(config);
      this.auth = app.auth();
      this.bd = app.database();
      this.storage = app.storage();
      // console.log(this);
    }

    doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
    
    addData = (url, data) => 
    this.database.ref(`1234`).update({status: 'new'});

    doSignOut = () => this.auth.signOut();
  }
  export default Firebase;