import React, { useState } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import { withFirebase } from '../firebase';
import { makeStyles } from '@material-ui/core';
import { format } from 'date-fns'
import { enGB, ru } from 'date-fns/locale'
import { DateRangePickerCalendar, START_DATE } from 'react-nice-dates'
import { getSpentMedals, getReceivedMedals } from '../redux/medals';

import { TextField, Button } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

import 'react-nice-dates/build/style.css'

const DateRangePickerCalendarComponent = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const [focus, setFocus] = useState(START_DATE);

  const handleFocusChange = newFocus => {
    setFocus(newFocus || START_DATE)
  }

  const handleOpenCalendar = () => {
      setOpen(!open);
  }

  const getTimeStamp = (time) => {
    const dateTime = new Date(time).getTime();
    const timestamp = Math.floor(dateTime / 1000);
    var key = parseInt(timestamp/86400);

    return String(key);
  }

  const handleSubmit = () => {
        const start = format(startDate, 'MMM dd, yyyy 12:00:00', { locale: enGB });
        const end = format(endDate, 'MMM dd, yyyy 12:00:00', { locale: enGB });
        const startTimeStamp = getTimeStamp(start);
        const endTimeStamp = getTimeStamp(end);

        console.log('startDate', startDate);

        const receivedMedalsRef = props.firebase.bd.ref('awards').child('receivedMedals');
        const spentMedalsRef = props.firebase.bd.ref('awards').child('spentMedals');

        let receivedMedalsPageQuery = receivedMedalsRef.orderByKey().startAt(startTimeStamp).endAt(endTimeStamp);
        let spentMedalsPageQuery = spentMedalsRef.orderByKey().startAt(startTimeStamp).endAt(endTimeStamp);

        // console.log('startTimeStamp', startTimeStamp);
        // console.log('endTimeStamp', endTimeStamp);

        spentMedalsPageQuery.once('value', function(snapshot) {
            const val = snapshot.val();

            console.log('val spentMedalsPageQuery', val);

            if (val) dispatch(getSpentMedals(val));
            if (!val) dispatch(getSpentMedals({}));

            setOpen(false);
        });

        receivedMedalsPageQuery.once('value', function(snapshot) {
          const val = snapshot.val();

          console.log('val receivedMedalsPageQuery', val);

          if (val) dispatch(getReceivedMedals(val));
          if (!val) dispatch(getReceivedMedals({}));
          setOpen(false);
      });

        // console.log('spentMedals', spentMedals);
  }

  const getDate = (start, end) => start && end ? `${moment(startDate).format('DD/MM/YYYY')} - ${moment(end).format('DD/MM/YYYY')}` : start && !end ? moment(startDate).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY');
  const defaultValueDate = getDate(startDate, endDate);

  // console.log('startDate', startDate);
  // console.log('endDate', endDate);
  // console.log('defaultValueDate', defaultValueDate);

  return (
    <div className={classes.root}>
      <div className={classes.calendar}>
            <TextField 
                className={classes.calendarField} 
                size="small"
                variant="standard"
                defaultValue={defaultValueDate}
                value={defaultValueDate}
                onClick={handleOpenCalendar}
            />
            <Paper className={classes.calendarPicker} style={{ opacity: open ? '100%' : '0' }}>
                <DateRangePickerCalendar
                    startDate={startDate}
                    endDate={endDate}
                    focus={focus}
                    onStartDateChange={setStartDate}
                    onEndDateChange={setEndDate}
                    onFocusChange={handleFocusChange}
                    locale={ru}
                />
                <div className={classes.buttons}>
                    <Button onClick={() => setOpen(false)} className={classes.button} variant="contained" color="secondary">Отмена</Button>
                    <Button onClick={startDate && endDate ? handleSubmit : null} className={classes.button} variant="contained" color="primary">Подтвердить</Button>
                </div>
            </Paper>
      </div>
    </div>
  )
}

const useStyles = makeStyles(theme => ({
    root: {
        
    },
    calendar: {
    },
    calendarField: {
      width: 225,
    },
    calendarPicker: {
        position: 'fixed',
        top: '50%',
        left: '50%',
        width: 300,
        height: 'auto',
        marginLeft: -150,
        marginTop: -150,
        background: 'white',
        zIndex: 0,
        [theme.breakpoints.down('sm')]: {
          top: '425px',
          width: 285,
        },
    },
    buttons: {
        paddingTop: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alighItems: 'center',
    },
    button: {
        margin: '10px 12px 24px 12px',
        fontSize: 13,

    }
}));

export default withFirebase(DateRangePickerCalendarComponent);