import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import { ThemeProvider } from '@material-ui/core/styles';
import Firebase, { FirebaseContext } from './firebase';

import App from './App';
import store from './redux/redux-store';
import theme from './themes';

ReactDOM.render(
  <FirebaseContext.Provider value={new Firebase('mamamanana-c8090')}>
    <BrowserRouter>
        <ThemeProvider theme={theme}>
          <Provider store={store}>
              <App/>
          </Provider>
        </ThemeProvider>
    </BrowserRouter>
    </FirebaseContext.Provider>,
  document.getElementById('root')
);
