import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { getProducts, updateProductsByKiev, updateProductsByKharkiv } from './redux/products';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Form from './layers/form';

import CircularProgress from '@material-ui/core/CircularProgress';

const App = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const productsByKiev = useSelector(state => state.products.productsByKiev);
  const productsByKharkiv = useSelector(state => state.products.productsByKharkiv);

  const getProductsByCity = (nameCity) => {
    getProducts(nameCity)
    .then(res => {
      if (res.products) {
        if (nameCity === 'kyiv') {
          dispatch(updateProductsByKiev(res.products));
        } else {
          dispatch(updateProductsByKharkiv(res.products));
        }
      }
    })
    .catch(err => console.err(err))
  }

  useEffect(() => {
    getProductsByCity('kyiv');
    getProductsByCity('kharkiv');
  }, []);

  return (
    <div className={classes.root}>
      <Grid container justify="center" alignItems="center">
        <Grid item md={6} sm={10} xs={11}>
        <Typography gutterBottom variant="h5">Сервис по получению медалей</Typography>
          {(productsByKiev.length > 0 && productsByKharkiv.length > 0) ? <Paper className={classes.paper}>
              <Form />
          </Paper> : <CircularProgress /> }
        </Grid>
      </Grid>
    </div>
  )
}

const useStyles = makeStyles({
  root: {
    marginTop: '3rem',
  },
  paper: {
    padding: '1rem'
  }
});

export default App;